import React, { useState } from "react";
import { navigate } from "gatsby";

import Layout from "../components/Layout/Layout";
import { isLoggedIn } from "../utils/services/methods";

const SSO = () => {
  const [loading, setLoading] = useState(true);
  if (typeof window === "object") {
    const userLanguage = navigator.language || navigator.userLanguage;
    isLoggedIn() ? navigate(`/dashboard/${userLanguage?.includes("es") ? "es" : ""}`) : navigate("/");
  }

  return <Layout loading={loading}></Layout>;
};

export default SSO;
